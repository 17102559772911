import formatString from 'string-template';

import type { StrOrNum } from '../typescript';

type IsParameter<Part> = Part extends `{${infer ParamName}}` ? ParamName : never;

type FilteredParts<Path> = Path extends `${infer PartA}/${infer PartB}`
    ? IsParameter<PartA> | FilteredParts<PartB>
    : IsParameter<Path>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
type ParamValue<Key> = Key extends `...${infer _Anything}` ? StrOrNum[] : StrOrNum;

type RemovePrefixDots<Key> = Key extends `...${infer Name}` ? Name : Key;

type Params<Path> = {
    [Key in FilteredParts<Path> as RemovePrefixDots<Key>]: ParamValue<Key>;
};

type StrTemplateCallbackFn<Path> = (req?: Params<Path>) => string;

/**
 * A maker fn that returns a callback. It's callback's parameters, if any,
 * are strictly typed key names with `string | number` values.
 * This is used to specifically to generate dynamic api paths.
 *
 * @example
 * ```
 *  const getUserEndpoint = makeStringTemplate('/api/user/{userId}');
 *  const query = (userId: string) => getUserEndpoint({ userId }) // they key will be typed
 * ```
 */
const makeStringTemplate =
    <PathTemplate extends string>(templateApiUrl: PathTemplate): StrTemplateCallbackFn<PathTemplate> =>
    variables =>
        formatString(templateApiUrl, variables);

export { makeStringTemplate };
