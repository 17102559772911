import { Knock } from '@knocklabs/node';

class KnockClient {
    private static instance: KnockClient;

    private knockClient?: Knock;

    private constructor() {
        if (!process.env.KNOCK_API_KEY) {
            console.warn('Knock API key missing - initializing no-op client');
        } else {
            this.knockClient = new Knock(process.env.KNOCK_API_KEY);
        }
    }

    static getInstance() {
        if (!KnockClient.instance) {
            KnockClient.instance = new KnockClient();
        }

        return KnockClient.instance;
    }

    async run(methods: (client: Knock) => Promise<unknown>) {
        if (this.knockClient) {
            return methods(this.knockClient);
        }
        return undefined;
    }
}

export const knockClient = KnockClient.getInstance();
