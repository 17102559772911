import NextAuth, { NextAuthConfig, Session } from 'next-auth';
import { JWT } from 'next-auth/jwt';

import { authApi } from '@blockworks/platform/api/research/auth';
import { userApi } from '@blockworks/platform/api/research/user';

import { baseAuthConfig, JWTParams, NextAuthInstance } from '@/api/auth/auth.config';
import { apiError, validateToken } from '@/api/auth/auth.schema';
import { SessionManager } from '@/api/auth/auth.store';
import { knockClient } from '@/utils/knock/knock-client';

// For more information on each option (and a full list of options) go to
// https://next-auth.js.org/configuration/options
export const { handlers, auth, signIn, signOut }: NextAuthInstance = NextAuth({
    ...baseAuthConfig,
    callbacks: {
        signIn: async ({ user, account, profile }) => {
            if (!user?.id) {
                return false;
            }
            // WARNING: `user` is mutable
            user.sessionToken = (user?.sessionToken ?? account?.id_token ?? '').toString();
            // Check for Oauth login
            // - Create/update user on backend since user might not be known (e.g. first sign-in)
            if (account && account?.provider !== 'credentials') {
                const { provider } = account;
                const body = {
                    name: user.name ?? user.email,
                    email: user.email,
                    image: user.image ?? '',
                    id: user.id,
                    provider,
                    phoneNumber: '',
                    authToken: account.id_token,
                    authTokenType: account.token_type,
                    accessToken: account.access_token ?? '',
                    expiresAt: account.expires_at,
                    emailVerified: profile && 'email_verified' in profile ? profile.email_verified : '1',
                    firstname: profile && 'given_name' in profile ? (profile.given_name as string) : null,
                    lastname: profile && 'family_name' in profile ? (profile.family_name as string) : null,
                };
                const userRegistration = await authApi.post.session(body).catch(err => {
                    if (err?.error?.startsWith('Call to a member function save() on null')) {
                        console.warn('Session was created with errors');
                        return { data: { status: 1 } };
                    } else {
                        console.error(err);
                    }
                    return { data: { status: 0 } };
                });

                if (userRegistration?.data?.status === 0) {
                    console.error('Signin Error: API Session failed', userRegistration);
                    return false;
                }
            }
            const sessionToken = account?.id_token || user.sessionToken;

            if (!sessionToken) {
                console.error('Signin Error: Missing Session Token');
                return false;
            } else {
                /**
                 * since we are using the `X-Research-Session-Token` header here this
                 * may have a collision return an incorrect user object if an identical token
                 * has been used for an existing user session.  This is extremely rare but is possible as
                 * the tokens here are not guaranteed to be unique.
                 *
                 * We can detect if this has failed by fetching the user for the given session token, and comparing
                 * the stored user data.  If the user information does not match the session we attempted to create
                 * we can safely fail the login and ask them to login again - as the token we recieve will be different
                 * each login.
                 */
                const userRes = await userApi.get.byId({ id: user.id, sessionToken });
                if (userRes.data?.email !== user.email) {
                    return false;
                } else {
                    // Addtionally mutate user to remove duplicate requests
                    Object.assign(user, userRes.data);
                }
            }

            return true;
        },
        jwt: async (params: JWTParams) => {
            const { token } = params;
            const syncRequests: unknown[] = [];
            const isMutation = params.trigger === 'signIn' || params.session?.type;

            if (params.trigger === 'signIn') {
                const { user, account } = params;
                const baseUser = {
                    id: user.id!,
                    sessionToken: account?.id_token || user.sessionToken,
                };

                const profileRes = await userApi.get.userProfile(baseUser);
                syncRequests.push(profileRes);

                token.user = {
                    ...user,
                    colorTheme: profileRes.data?.theme ?? 'System',
                };
            } else {
                const { session: action } = params;

                if (SessionManager.isAvailable) {
                    const currentUser = await SessionManager.get(token.user);
                    // Invalidate duplicate sessions for a single user
                    if (currentUser?.sessionToken !== token?.user?.sessionToken) {
                        console.error('Session Error: API Session Expired');
                        return null;
                    }

                    token.user = {
                        ...token.user,
                        ...currentUser,
                    };
                }

                // Refresh user cache is if session update is triggered
                if (action?.type === 'refresh') {
                    const userRes = await userApi.get.byId({
                        id: token.user.id,
                        sessionToken: token.user.sessionToken,
                    });

                    // Invalidate token in case of collision immediately.
                    if (userRes.data?.email !== token.user.email) {
                        return null;
                    }

                    syncRequests.push(userRes);

                    token.user = {
                        ...token.user,
                        ...userRes.data,
                    };
                } else if (action?.type === 'updateTheme') {
                    const profileRes = await userApi.get.userProfile(token.user);
                    const updateRes = await userApi.post.updateUserProfile({
                        sessionToken: token.user.sessionToken,
                        body: { ...profileRes.data, theme: action.payload },
                    });

                    syncRequests.push(profileRes, updateRes);

                    token.user = {
                        ...token.user,
                        colorTheme: action.payload,
                    };
                }

                // if any server requests fail due to a session error invalidate this session
                const hasAuthErrors = syncRequests.some(res => apiError.isValidSync(res));

                if (hasAuthErrors) {
                    console.error('Session Error: API Session Expired');
                    return null;
                }
            }
            // If token may have new data store in the session else trust hydration request
            if (isMutation && SessionManager.isAvailable) {
                token.user = await SessionManager.set(token.user);
            } else {
                token.user = validateToken(token.user);
            }

            return token;
        },
        session: async ({ session, token }: { session: Session; token: JWT }) => {
            if (token === null || token.user === null) return null;
            session.user = token.user;
            return session;
        },
    },
    events: {
        signIn: async ({ user }) => {
            await knockClient.run(async client => {
                if (!user.email) return undefined;
                return client.users.identify(user.email, {
                    name: user.name ?? user.email,
                    email: user.email,
                    avatar: user.image,
                });
            });
        },
        signOut: async ({ token }: { token: JWT }) => {
            await SessionManager.del(token.user);
        },
    },
} as NextAuthConfig);
