import { GetServerSidePropsContext, NextApiRequest, NextApiResponse, NextPageContext } from 'next';
import { NextRequest } from 'next/server';
import {
    Account,
    AppRouteHandlerFn,
    AppRouteHandlerFnContext,
    CredentialsSignin,
    NextAuthResult,
    Profile,
    Session,
    SessionUser,
    User,
} from 'next-auth';
import { JWT } from 'next-auth/jwt';
import CredentialsProvider from 'next-auth/providers/credentials';
import GoogleProvider from 'next-auth/providers/google';
import MicrosoftEntraIdProvider from 'next-auth/providers/microsoft-entra-id';

import { authApi } from '@blockworks/platform/api/research/auth';

import { AppRoutes } from '@/api/auth/auth.routes';

const VERSION = 'v3';

export type NextAuthInstance = Omit<NextAuthResult, 'auth'> & {
    auth: ((...args: [NextApiRequest, NextApiResponse]) => Promise<Session | null>) &
        ((...args: [NextPageContext]) => Promise<Session | null>) &
        ((...args: [GetServerSidePropsContext]) => Promise<Session | null>) &
        ((
            ...args: [
                (
                    req: NextRequest & { auth: Session | null },
                    ctx: AppRouteHandlerFnContext,
                ) => ReturnType<AppRouteHandlerFn>,
            ]
        ) => AppRouteHandlerFn);
};

export type RefreshSessionAction = { type: 'refresh' };
export type UpdateThemeAction = { type: 'updateTheme'; payload: SessionUser['colorTheme'] };
export type SessionActions = RefreshSessionAction | UpdateThemeAction;

export type JWTParams =
    | { trigger: 'signIn'; token: JWT; user: User; account: Account | null; profile?: Profile | undefined }
    | {
          trigger: undefined;
          token: JWT;
          session?: SessionActions;
      };

export enum AuthCookies {
    sessionToken = `${VERSION}:sessionToken`,
    csrfToken = `${VERSION}:csrfToken`,
    callbackUrl = `${VERSION}:callbackUrl`,
    state = `${VERSION}:state`,
    pkceCodeVerifier = `${VERSION}:pkceCodeVerifier`,
    nonce = `${VERSION}:nonce`,
    webauthnChallenge = `${VERSION}:webauthnChallenge`,
}

export const baseAuthConfig = {
    redirectProxyUrl: process.env.AUTH_REDIRECT_PROXY_URL,
    providers: [
        // Email + Password provider
        CredentialsProvider({
            id: 'credentials',
            name: 'Credentials',
            credentials: {
                email: { label: 'Email', type: 'text' },
                password: { label: 'Password', type: 'password' },
            },
            authorize: async credentials => {
                let errorMessage;

                const login = await authApi.post
                    .login({
                        email: credentials?.email as string,
                        password: credentials?.password as string,
                    })
                    .catch(error => {
                        console.error(error);
                        errorMessage = `${error}`;
                    });

                if (!login) {
                    errorMessage = 'Error: Could not authenticate.';
                    // You can also Reject this callback with an Error thus the user will be sent to the error page with the error message as a query parameter
                }

                if (!login.data) {
                    errorMessage = 'Error: Login response malformed.';
                }

                // Login failed
                if (login.data.status === 0) {
                    errorMessage = login.data?.message ?? 'Invalid username or password.';
                }
                if (errorMessage) {
                    throw new CredentialsSignin(errorMessage);
                }

                // Login succeeded, prepare user object
                return {
                    id: login.data.userId,
                    name: `${login.data.firstname} ${login.data.lastname}`,
                    email: login.data.email,
                    image: login.data.image,
                    status: login.data.userStatus,
                    sessionToken: login.data.authToken,
                    subscriptionStatus: login.data.subscriptionStatus,
                    customerId: login.data.customerId ?? null,
                };
            },
        }),
        GoogleProvider({
            clientId: process.env.GOOGLE_ID ?? '',
            clientSecret: process.env.GOOGLE_SECRET ?? '',
        }),
        MicrosoftEntraIdProvider({
            clientId: process.env.AZURE_AD_CLIENT_ID ?? '',
            clientSecret: process.env.AZURE_AD_CLIENT_SECRET ?? '',
            tenantId: process.env.AZURE_AD_TID,
        }),
    ],
    session: {
        strategy: 'jwt',
    },
    pages: {
        signIn: AppRoutes.SIGN_IN.pathname,
        newUser: AppRoutes.HOME.pathname,
        error: AppRoutes.SIGN_IN.pathname,
    },
    cookies: {
        sessionToken: { name: AuthCookies.sessionToken },
        csrfToken: { name: AuthCookies.csrfToken },
        callbackUrl: { name: AuthCookies.callbackUrl },
        state: { name: AuthCookies.state },
        nonce: { name: AuthCookies.nonce },
        pkceCodeVerifier: { name: AuthCookies.pkceCodeVerifier },
        webauthnChallenge: { name: AuthCookies.webauthnChallenge },
    },
};
