import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

const { fontSize, fontWeight, lineClamp, textAlign, ...originalTypographyProps } = factoryStyleProps.typography;

const textStyles = tVariants(
    {
        variants: {
            color: factoryStyleProps.color.color,
            cursor: {
                pointer: 'cursor-pointer',
                default: 'cursor-default',
            },
            inline: { true: 'inline' },
            uppercase: { true: 'uppercase' },
            underline: { true: 'underline' },
            capitalize: { true: 'capitalize' },
            center: { true: 'text-center' },
            lines: {
                1: 'line-clamp-1',
                2: 'line-clamp-2',
                3: 'line-clamp-3',
                4: 'line-clamp-4',
                5: 'line-clamp-5',
                6: 'line-clamp-6',
                none: 'line-clamp-none',
            },
            weight: {
                regular: 'font-normal',
                medium: 'font-medium',
                semibold: 'font-semibold',
                bold: 'font-bold',
            },
            align: {
                left: 'text-left',
                center: 'text-center',
                right: 'text-right',
                justify: 'text-justify',
                start: 'text-start',
                end: 'text-end',
            },
            size: {
                inherit: 'inherit',
                xxs: 'text-xxs',
                xs: 'text-xs',
                sm: 'text-sm',
                md: 'text-md',
                lg: 'text-lg',
                xl: 'text-xl',
                '2xl': 'text-2xl',
                '3xl': 'text-3xl',
                '4xl': 'text-4xl',
                '5xl': 'text-5xl',
                'display-xs': 'text-display-xs',
                'display-sm': 'text-display-sm',
                'display-md': 'text-display-md',
                'display-lg': 'text-display-lg',
                'display-xl': 'text-display-xl',
            },
            ...originalTypographyProps,
            ...factoryStyleProps.__atoms.space.margin,
            ...factoryStyleProps.__atoms.space.padding,
        },
    },
    {
        responsiveVariants: true,
    },
);

export { textStyles };
