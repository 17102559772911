import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

const menuItemStyles = tVariants({
    base: 'group/link peer/link inline-flex w-full transition-colors items-center grow border-0 text-content-deselected rounded-md hover:text-content',
    slots: {
        menuItem: 'group-[&.sub]/menu:px-0 px-2 group/menu-item clickable clickable-rounded-md',
        accordion:
            'group/accordion clickable-target z-[0] data-open:pb-1 data-open:clickable-bg-surface-muted clickable-rounded-md',
        accordionTarget: 'flex items-center pr-1',
        icon: 'w-[1rem] h-[1rem] aspect-square group-data-active/link:text-primary',
        toggle: 'group/toggle p-1 rounded-md hover:bg-surface-muted group-data-open/accordion:hover:bg-surface-selected  peer-data-active/link:hover:bg-surface-selected transition-colors',
        toggleIcon:
            'w-4 h-4 aspect-square text-content-deselected transition-transform duration-200 group-data-open/toggle:rotate-180',
    },
    variants: {
        ml: factoryStyleProps.space.ml,
        maxW: factoryStyleProps.size.maxW,
        disabled: { true: 'opacity-60 pointer-events-none' },
        variant: {
            default:
                'group clickable-target bg-transparent hover:clickable-bg-surface-muted data-active:clickable-bg-surface-muted group-[&.sub]/menu:hover:bg-surface-selected group-[&.sub]/menu:data-active:bg-surface-selected data-active:text-content data-active:font-semibold',
            link: {
                base: [
                    'group clickable-target bg-transparent',
                    'hover:text-primary group-[&.sub]/menu:hover:text-primary',
                    'group-[&.sub]/menu:data-active:text-primary-highlight data-active:text-primary-highlight',
                ].join(' '),
                menuItem: 'px-1',
            },
            expandable:
                'clickable-target bg-transparent hover:clickable-bg-surface-muted data-active:clickable-bg-surface-muted',
        },
        truncate: { true: 'truncate' },
        rounded: {
            true: {
                menuItem: 'rounded-full',
            },
        },
        size: {
            xs: 'py-1 px-2 text-xs min-h-7 gap-2',
            sm: 'py-2 group-[&.sub]/menu:py-1.5 px-2 text-sm min-h-9 gap-2.5',
        },
    },
});

export { menuItemStyles };
