import { tVariants } from '../../style-system';

const layoutStyles = tVariants({
    base: 'font-sans h-screen print:h-max w-screen bg-surface relative z-[0]',
    variants: {
        grid: { true: 'grid grid-rows-layout grid-cols-mobile md:grid-cols-layout -m-[1px] ' },
    },
});

const topbarStyles = tVariants({
    base: 'w-full row-start-1 col-start-2 col-span-2 px-2 py-2  border-b border-surface-divider flex items-center justify-end',
});

const sidebarStyles = tVariants({
    base: 'col-start-1 row-start-2 col-span-2 bg-surface border-r border-surface-divider overflow-y-auto min-w-[--sidebar-width] max-w-[--sidebar-width] hidden md:block print:md:hidden',
});

const contentStyles = tVariants({
    base: 'col-start-1 col-end-3 w-full max-h-full print:max-h-max print:overflow-y-visible overflow-y-auto bg-surface-depth',
    variants: {
        withSidebar: { true: 'col-start-2 row-start-2' },
    },
});

const brandStyles = tVariants({
    base: 'row-start-1 col-start-1 bg-surface px-1 border-r border-b border-surface-divider items-center justify-center data-[state="hide-on-mobile"]:hidden md:data-[state="hide-on-mobile"]:flex data-[state="show-on-mobile"]:flex data-[state="show-on-mobile"]:min-w-max',
    variants: {
        padded: { true: 'px-3' },
    },
});

export { brandStyles, contentStyles, layoutStyles, sidebarStyles, topbarStyles };
