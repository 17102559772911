import { ariaAttr, callAllHandlers, safeAttr } from '../../../../utils';

import { useFieldContext } from './field-context';
import type { UseFieldProps } from './field-context.model';

type UseFieldPropRules = {
    includeOrientationRootProp?: boolean;
};

const useFieldProps = <T extends HTMLElement = HTMLInputElement>(
    props: UseFieldProps<T>,
    rules: UseFieldPropRules = {},
) => {
    const field = useFieldContext();

    const {
        id,
        disabled,
        readOnly,
        required,
        invalid,
        onFocus,
        onBlur,
        'aria-describedby': ariaDescribedby,
        size: sizeProp,
        orientation: orientationProp,
        ...restProps
    } = props;

    const orientation = field?.orientation ?? orientationProp;
    const size = field?.size ?? sizeProp;

    const labelIds: string[] = ariaDescribedby ? [ariaDescribedby] : [];

    // Error message must be described first
    if (field?.hasFeedbackText && field?.invalid) {
        labelIds.push(field.feedbackId);
    }

    if (field?.hasHelpText) {
        labelIds.push(field.helpTextId);
    }

    const combinedInvalid = invalid ?? field?.invalid;
    const combinedRequired = required ?? field?.required;
    const combinedReadOnly = readOnly ?? field?.readOnly;

    return {
        fieldProps: {
            ...restProps,
            'aria-describedby': labelIds.join(' ') || undefined,
            'aria-invalid': ariaAttr(combinedInvalid),
            'aria-required': ariaAttr(combinedRequired),
            'aria-readonly': ariaAttr(combinedReadOnly),
            id: id ?? field?.id,
            disabled: disabled ?? field?.disabled,
            readOnly: combinedReadOnly,
            required: combinedRequired,
            invalid: combinedInvalid,
            onFocus: callAllHandlers(field?.onFocus, onFocus),
            onBlur: callAllHandlers(field?.onBlur, onBlur),
        },
        rootProps: {
            ...safeAttr(!!orientation && rules.includeOrientationRootProp, orientation, {
                prefix: 'aria',
                suffix: 'orientation',
            }),
            'data-state': combinedInvalid ? 'error' : '',
        },
        orientation,
        size,
    };
};

type UseFieldPropsReturn = ReturnType<typeof useFieldProps>;

export type { UseFieldPropsReturn };
export { useFieldProps };
