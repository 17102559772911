import React from 'react';

import { useBoolean } from '@blockworks/platform/hooks';
import { Bit } from '@blockworks/ui/bit';
import { Button, FlexBox, Popover, Text, Tooltip } from '@blockworks/ui/components';
import { RelativeTime } from '@blockworks/ui/date-time';
import { GasolinePumpIcon, InfoCircleIcon } from '@blockworks/ui/icon';

import { assetQueries } from '@/api/assets/queries';

const usages = [
    {
        id: 'fast',
        icon: '🚀',
        label: 'Fast',
    },
    { id: 'proposed', icon: '🚙', label: 'Average' },
    { id: 'safe', icon: '🐢', label: 'Slow' },
] as const;

const GasPricePopover = () => {
    const { value: isOpen, setValue: setIsOpen } = useBoolean(false);
    const gasQuery = assetQueries.getEthGas.use();

    return (
        <Popover
            open={isOpen}
            onOpenChange={setIsOpen}
            trigger={
                <Button variant="ghost" active={isOpen} leadingIcon={GasolinePumpIcon} size="xs">
                    {gasQuery.data?.fast ?? <Bit.div pl={3}></Bit.div>}
                </Button>
            }
            contentProps={{ align: 'end' }}
        >
            <div className="w-[225px]">
                <FlexBox col px={2} pb={0}>
                    <FlexBox justifyContent="between" alignItems="center" gap="md" py={2} pl={1} reverse>
                        <Popover.Close />
                        <FlexBox alignItems="center" gap="sm">
                            <Text size="sm" color="deselect">
                                Realtime gas prices
                            </Text>
                            <Tooltip side="bottom">
                                <Tooltip.Trigger>
                                    <InfoCircleIcon className="text-content-deselected w-4 h-4" />
                                </Tooltip.Trigger>
                                <Tooltip.Content>
                                    <FlexBox col p={1}>
                                        <Text align="center" size="xs" weight="semibold">
                                            Gas prices are powered by Etherscan
                                        </Text>
                                        <Text align="center" size="xs" color="deselect">
                                            Last updated at <RelativeTime date={Number(gasQuery.data?.updated)} />
                                        </Text>
                                    </FlexBox>
                                </Tooltip.Content>
                            </Tooltip>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox border="divide-y" col>
                        {gasQuery.data &&
                            usages.map(({ id, label, icon }) => (
                                <FlexBox key={id} p={2} px={1} gap="sm">
                                    <Text size="sm">{icon}</Text>
                                    <Bit.div flexGrow={1}>
                                        <Text size="sm">{label}</Text>
                                    </Bit.div>
                                    <Text size="sm" weight="semibold">
                                        {gasQuery.data[id]} GWEI
                                    </Text>
                                </FlexBox>
                            ))}
                    </FlexBox>
                </FlexBox>
            </div>
        </Popover>
    );
};

export default GasPricePopover;
